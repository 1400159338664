import React from 'react';
import { useState } from 'react';

const Resume = () => {
	return (
    <>
    </>
  )
};

export default Resume;

